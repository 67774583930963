import api from "../httpClient.js";

export async function getEventosNotas() {
  const response = await api.get("/eventosnotas");
  return response.data.eventosnotas;
}

export async function getEventosNotasPorEvento(filtros) {
  const response = await api.post(`/eventosnotas${filtros}`);
  return response.data.eventosnotas;
}

export async function getEventosNota(id) {
  const response = await api.get(`/eventosnotas/${id}`);
  return response.data.eventosnota;
}

export async function uploadImage(fd) {
  const response = await api.post("/eventosnotas/uploadImage", fd);
  return response;
}

export function putEventosNota(id, EventosNota) {
  let body = {};
  for (let key in EventosNota) {
    body[key] = EventosNota[key];
  }
  return api.put(`/eventosnotas/${id}`, body);
}

export function postEventosNota(EventosNota) {
  let body = {};
  for (let key in EventosNota) {
    body[key] = EventosNota[key];
  }
  return api.post("/eventosnotas/add", body);
}
